@use "@angular/cdk" as cdk;

@import "../app/vars";

.filler {
  flex: 1 1 auto;
}

.draggable {
  cursor: move !important;
}

.cdk-drag-preview {
  padding: 5px;
  font-family: "Inter";
}

.desktop-container {
  max-width: 1000px;
  padding-top: 140px;
  margin: 0 auto;
  min-height: 650px;
}
// .content-padding {
//   padding: 64px 0;
//   @media screen and (max-width: 767px) {
//     padding: 80px 0 70px 0;
//   }
// }
.content-margin {
  margin: 70px 0;
  @media screen and (max-width: 991px) {
    margin: 60px 0;
  }
}
.section-padding {
  padding: 70px 0;
  // @media screen and (max-width: 991px) {
  //   padding: 70px 0;
  // }
}

.slim-page {
  min-width: 700px;
  padding-top: 140px;
}

.form-buttons {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 5px;
}

.page-title {
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 26px;
  // line-height: 30px;
  color: var(--text-primary);
  margin-top: 0px;
  margin-bottom: 2rem;
  @media screen and (max-width: 991px) {
    margin-bottom: 1.2rem;
  }
}

.page-section {
  display: flex;
  justify-content: center;
  align-content: center;
}

.section-label {
  margin-bottom: 10px;
  margin-top: 30px;
  font-size: 1.17em;
  font-weight: bold;
}

.dialog-actions {
  display: flex;
  justify-content: flex-end;
}

.file-upload {
  position: absolute;
  cursor: pointer;
  display: none;
}

.buy-button {
  background: linear-gradient(#d60d17, #730004);
}

.buy-button:disabled {
  background: rgba(0, 0, 0, 0.26);
  cursor: not-allowed;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.tab-container {
  display: flex;
  max-width: 1000px;
  flex-direction: column;
  margin: 10px;
  padding: 30px;
}

.add-button-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 10px 10px 10px;
}

.add-button-panel button {
  margin-top: 20px;
}

.controls-form {
  display: flex;
  flex-direction: column;
}

.controls-form .toggle-command {
  margin-bottom: 20px;
}

.form-buttons button {
  margin-left: 10px;
}

.form-field-error {
  color: #f44336;
  font-size: 14px;
  font-family: var(--fonts);
}

.field-hint {
  font-family: var(--fonts);
  margin-top: 3px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 13px;
}

mat-divider.divider {
  margin-top: 20px;
}

.no-content {
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 100px 10px 0 10px;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.spinner-container {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background: cdk.$overlay-backdrop-color;
  z-index: 2000;
}

.text-box {
  border-radius: 4px;
  background: #dcdcdc;
  padding: 15px;
  font-size: 16px;
  color: rgba(26, 35, 38, 0.8);
  line-height: 25px;
  display: flex;
  .edit-button {
    align-self: flex-end;
  }
}

mat-card.card-section {
  padding: 0;
  background: #fafafa;
  .mat-toolbar-single-row {
    height: 40px;
    font-size: 18px;
  }
  & mat-card-content {
    padding: 16px;
  }
  max-width: 700px;
  margin: 0 auto 60px auto;
}

.card-note-container {
  display: flex;
  .card-logo {
    margin-top: 10px;
    width: 111px;
    height: 69px;
  }
  .card-notes {
    margin-left: 10px;
  }
}

.warning {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-family: var(--fonts);

  .mat-icon {
    margin-right: 10px;
  }
}

.lesson-row {
  display: flex;
  align-items: center;
  min-height: 48px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(26, 35, 38, 0.8);
  cursor: pointer;
  outline: none;
  padding-left: 10px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.lesson-row:hover {
  background: #f2f3f5;
  border-top-color: #f2f3f5;
}

.lesson-icon {
  flex: 0 0 35px;
  color: rgba(22, 28, 26, 0.87);
  display: flex;
}
.lesson-list-icon {
  max-height: 27px;
  margin-right: 5px;
}

.lesson-number {
  flex: 0 0 20px;
  margin-left: 3px;
  margin-right: 3px;
}

.lesson-pro {
  flex: 0 0 25px;
  margin-right: 10px;
}

.lesson-duration {
  display: flex;
  margin-left: 15px;
  margin-right: 15px;
  align-items: center;

  .duration-icon {
    margin-right: 10px;
  }
}

.lesson-viewed {
  margin-left: 10px;
  margin-right: 15px;
}

.pro-icon {
  color: #fff;
  background: #0280e2;
  border-radius: 3px;
  font-size: 16px;
  text-align: center;
  vertical-align: middle;
  line-height: 20px;
  width: 20px;
  height: 20px;
}

$active-row-background: #05677d;

.lesson-row.active-lesson {
  background: $active-row-background;
  color: white;

  .lesson-icon {
    color: white;
  }
}

.lesson-row.active-lesson:hover {
  background: $active-row-background;
  border-top-color: $active-row-background;
  color: white;
}

em {
  font-style: italic;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.5em 0 !important;
  border-top: 0.42em solid transparent !important;
}

.not-allowed {
  cursor: not-allowed;
}

.newsletter-form {
  //padding: 0;
}

.info-note {
  display: flex;
  align-items: start;
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
  border-radius: 8px;
  padding: 8px 10px;
  mat-icon {
    margin-right: 5px;
  }
  svg {
    width: 1rem;
    height: 1rem;
  }
  .info-note-des {
    margin-left: 6px;
    font-size: 0.875rem;
  }
}

.warn-note {
  display: flex;
  align-items: center;
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
  border-radius: 4px;
  padding: 0 10px;
  mat-icon {
    margin-right: 5px;
  }
}

.stretch-container {
  display: flex;
  position: relative;

  .stretch-content {
    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.welcome-message {
  background: #fafafa !important;
  color: rgba(42, 48, 46, 0.87) !important;
}

.backdrop-modal-panel {
  background: rgba(0, 0, 0, 0.58);
}

.modal-panel,
.mat-mdc-dialog-container,
.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 10px !important;
}

.feature-row {
  display: flex;
  margin-bottom: 10px;
}

.feature-item {
  display: flex;
  background-color: rgba(0, 0, 0, 0.04);
  border-bottom: 1px solid lightgray;
  padding: 0.75em 0.25em 0 0.75em;
  height: 35px;
  align-items: flex-start;
}

// mobile
@media screen and (max-width: 959px) {
  .modal-panel {
    min-width: 0 !important;
    max-width: 100% !important;
    max-height: 100% !important;
    width: 100% !important;
    height: 100% !important;

    .mat-dialog-container {
      border-radius: 0;
      padding: 14px !important;
    }
  }
}
// phone portrait
@media screen and (max-width: 500px) {
  pre {
    max-width: unset !important;
  }
}

.ql-view-html {
  .htmlembed {
    iframe {
      width: 100%;
    }
  }

  .ql-editor {
    padding: 0px;
    p {
      color: #2d343f;
      margin: 1rem 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #051e40;
    }

    p,
    ul li,
    ol li {
      // color: black;
      // font-size: 15px;

      color: #2d343f;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5625rem;
      word-break: break-word;
    }

    img {
      border-radius: 0.75rem;
    }
  }
}

.ql-editor {
  pad p {
    margin-bottom: 22px !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1em 0 !important;
  }

  p,
  blockquote {
    font-size: 16px;
    line-height: 1.5rem;
  }

  ul li,
  ol li {
    line-height: 22px;
  }
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-align-justify {
  text-align: justify;
}

.left-spacer {
  margin-right: auto;
}

.right-spacer {
  margin-left: auto;
}

.admin-screen {
  min-height: 420px;
}

//
//  reduce the weight of fonts on Firefox only
//
@-moz-document url-prefix() {
  .top-menu a {
    font-weight: 400 !important;
  }
  mat-toolbar {
    font-weight: 400 !important;
  }
  button.mat-raised-button {
    font-weight: 400 !important;
  }
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

// used to style the look and feel of video closed captions, if needed
// https://css-tricks.com/improving-video-accessibility-with-webvtt/
video::cue {
  max-width: 400px;
  font-family: sans-serif;
  font-weight: normal;
  color: white;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4vmin;
}

// fix the positioning of the embed url dialog, that pops up when we try to embed an YouTube video
body .ql-snow .ql-tooltip {
  left: 5px !important;
  min-width: 500px;
  input[type="text"] {
    width: 345px;
  }
}

body .ql-snow .ql-editor blockquote {
  margin-bottom: 20px;
}

.ql-video {
  width: 90% /*600px*/;
  height: 345px;
}

@media only screen and (max-width: 599.98px) and (orientation: portrait) {
  .ql-video {
    width: auto;
    height: auto;
  }
}

.html-content img {
  max-width: 100%;
}

.html-content ol li,
.html-content ul li {
  margin-bottom: 10px;
}

.html-content pre {
  background: var(--text-secondary);
  border-radius: 10px;
  padding: 20px 30px;
  color: var(--color-white);
  white-space: unset;
  word-break: break-all;
}

.html-content blockquote {
  border-left: 2px solid var(--text-link);
  padding-left: 22px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: unset;
  margin-right: unset;
}

.html-content a {
  text-decoration: none;
  cursor: pointer;
  color: var(--text-link);
  padding-left: 30px;
  background-color: transparent;
  background-image: url("https://d8avu785lzlph.cloudfront.net/images/neo-icons/link-clip.svg");
  background-repeat: no-repeat;
  background-position: left center;

  word-break: break-all;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Test Badge/Pill Styles [--START--]

.test-pill {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background: #fffcb0;
  width: fit-content;
  padding: 4px 10px;
  color: #d0a200;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
}

.test-pill-mt {
  margin-bottom: 5px;
}

.test-pill-mb {
  margin-bottom: 10px;
}

.test-pill-mr {
  margin-right: 10px;
}

.test-pill-ml {
  margin-left: 10px;
}

// Test Badge/Pill Styles [--END--]
