//form

.form-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    .main-label {
        font-size: 0.875rem;
        font-weight: 600;
        color: #11294f;
        margin-bottom: 6px;
    }
    .form-control,
    textarea {
        width: 100%;
        height: 100%;
        border: 1px solid #d7e2ea;
        color: #667182;
        font-size: 13px;
        line-height: 1rem;
        font-weight: 400;
        background-color: #f7f9fc;
        border-radius: 6px;
        padding: 14px 18px;
        font-family: Arial, Helvetica, sans-serif;
        &:focus {
            outline: none;
            box-shadow:
                rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
                rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        }
        &:disabled {
            border: 1px Solid #d7e2ea;
        }
    }
    .form-control.ng-invalid.ng-dirty,
    textarea.ng-invalid.ng-dirty {
        border: 1px solid red;
        &:focus {
            box-shadow: none;
        }
    }
    label {
        font-size: 11px;
        font-weight: 400;
        line-height: 13px;
        color: #a1b1c6;
        padding: 0 !important;
        margin-top: 5px;
    }
    .error {
        color: #eb3b3b;
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        display: flex;
        align-items: center;
        margin-top: 5px;
        .material-symbols-outlined {
            font-size: 13px;
            margin-right: 8px;
            overflow: visible !important;
        }
    }
    .form-input-ai {
        display: flex;
        align-items: center;
        .form-input-ai-wrapper {
            text-align: end;
            width: 100%;
        }
        .material-symbols-outlined.magic-icon {
            margin-left: 10px;
            margin-top: -20px;
            cursor: pointer;
            color: #004cbb;
        }
        .material-symbols-outlined.disabled {
            color: #c1c9d5;
        }
    }
    .ai-link-label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.75rem;
        .main-label {
            margin-bottom: 0px;
        }
        @media screen and (max-width: 600px) {
            flex-direction: column;
            align-items: start;
            .ai-btn-link {
                margin-top: 0.875rem;
                margin-left: auto;
            }
        }
    }
}
