@use "@angular/material" as mat;

@import "global-styles/global-vars";
@import "global-styles/fonts";
@import "global-styles/util";
@import "global-styles/bootstrap-grid";
@import "global-styles/components/och-checkbox";
@import "global-styles/components/och-radio";
@import "global-styles/components/och-input-field";
@import "global-styles/admin-common";
@import "global-styles/edit-course-common";
@import "global-styles/button-common";
@import "global-styles/custom-domain-common";
@import "global-styles/activate-plan-common";
@import "global-styles/new _components";
@import "global-styles/community-common";
@import "global-styles/page-builder-common";
@import "global-styles/analytics-common";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Inter, "Work Sans", sans-serif;
}
body.modal-open {
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}
*,
::after,
::before {
  box-sizing: border-box;
}

$custom-typography: mat.m2-define-typography-config(
  $font-family: '"Inter", "Work Sans", sans-serif'
);

@include mat.elevation-classes();
@include mat.app-background();

$dark-primary-text: rgba(black, 0.87);

$light-primary-text: white;

$mat-custom-grey: (
  50: #fafafa,
  100: #f5f5f5,
  200: #eeeeee,
  300: #e0e0e0,
  400: #bdbdbd,
  500: #9e9e9e,
  600: #757575,
  700: #616161,
  800: #424242,
  900: #212121,
  A100: #ffffff,
  A200: #eeeeee,
  A400: #bdbdbd,
  A700: #616161,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white
  )
);

$mat-custom-accent: (
  50: #fce4ec,
  100: #f8bbd0,
  200: #f48fb1,
  300: #f06292,
  400: #ec407a,
  500: #e91e63,
  600: #d81b60,
  700: #c2185b,
  800: #ad1457,
  900: #880e4f,
  A100: #ff80ab,
  A200: #eb3b3b,
  A400: #f50057,
  A700: #c51162,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white
  )
);

$primary: mat.m2-define-palette($mat-custom-grey, 500);

$accent: mat.m2-define-palette($mat-custom-accent, A200, A100, A400);

$theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent
    ),
    typography: $custom-typography,
    density: 0
  )
);

@include mat.all-component-themes($theme);

.mat-elevation-z1 {
  box-shadow:
    0 2px 1px -1px #0003,
    0 1px 1px #00000024,
    0 1px 3px #0000001f;
}

.mat-elevation-z2 {
  box-shadow:
    0 3px 1px -2px #0003,
    0 2px 2px #00000024,
    0 1px 5px #0000001f;
}

.mat-elevation-z3 {
  box-shadow:
    0 3px 3px -2px #0003,
    0 3px 4px #00000024,
    0 1px 8px #0000001f;
}

.mat-elevation-z4 {
  box-shadow:
    0 2px 4px -1px #0003,
    0 4px 5px #00000024,
    0 1px 10px #0000001f;
}

.mat-elevation-z5 {
  box-shadow:
    0 3px 5px -1px #0003,
    0 5px 8px #00000024,
    0 1px 14px #0000001f;
}

.mat-elevation-z6 {
  box-shadow:
    0 3px 5px -1px #0003,
    0 6px 10px #00000024,
    0 1px 18px #0000001f;
}

.mat-elevation-z7 {
  box-shadow:
    0 4px 5px -2px #0003,
    0 7px 10px 1px #00000024,
    0 2px 16px 1px #0000001f !important;
}

.mat-elevation-z8 {
  box-shadow:
    0 5px 5px -3px #0003,
    0 8px 10px 1px #00000024,
    0 3px 14px 2px #0000001f;
}

.mat-elevation-z9 {
  box-shadow:
    0 5px 6px -3px #0003,
    0 9px 12px 1px #00000024,
    0 3px 16px 2px #0000001f;
}

.mat-elevation-z10 {
  box-shadow:
    0 6px 6px -3px #0003,
    0 10px 14px 1px #00000024,
    0 4px 18px 3px #0000001f !important;
}
