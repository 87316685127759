//brand-buttons-end

button {
  cursor: pointer;
}

button.btn-primary,
button.btn-accent,
button.btn-cancel,
button.btn-light,
button.btn-main {
  cursor: pointer;
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 0;
  border-radius: 6px;
  height: 42px;
  width: 180px;
  border: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  position: relative;
  overflow: hidden;
  font-weight: 600;
  font-size: 13px;
  &::after {
    content: "";
    background: rgba(0, 0, 0, 0.1);
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px !important;
    margin-top: -120%;
    opacity: 0;
    transition: all 0.6s ease-in-out;
  }
  &:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s;
  }
  &:hover {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  }
  &:disabled {
    background-color: #bdc7d3 !important;
    cursor: no-drop !important;
    span {
      color: var(--color-white);
    }
  }
  .material-symbols-outlined {
    margin-right: 8px;
    font-size: 1rem !important;
    font-weight: 500;
  }
  svg {
    margin-right: 8px;
    width: 1rem;
    height: 1rem;
  }
}

.btn-main {
  cursor: pointer;
  background-color: #11294f !important;
  color: white !important;
  letter-spacing: 0px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0.8rem 0 !important;
  border-radius: 6px !important;
  height: 42px !important;
  width: 180px !important;
  border: none;
  .mdc-button__label {
    display: flex;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    padding: 0.75rem 1rem !important;
    margin-right: 0;
    width: fit-content !important;
  }
}
button.btn-cancel {
  border: 1px solid var(--text-color);
  box-shadow: none;
  background-color: var(--color-white);
  span {
    color: var(--text-color);
  }
  &:hover {
    box-shadow: none;
  }
  &:disabled {
    border: none;
  }
}
button.btn-light {
  // background-color: #f0f4f7 !important;
  background-color: #ffffff !important;
  span {
    color: var(--text-secondary);
  }
}
button.btn-sm {
  width: fit-content;
  padding: 0.8rem 1.8rem !important;
}

//brand-buttons-end

.btn-secondary {
  background-color: #f0f4f7;
  width: auto;
  padding: 0.8rem;
  border: none;
  display: flex;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  .material-symbols-outlined {
    margin-right: 8px;
    font-size: 1rem;
  }
  span {
    color: var(--text-link);
  }
}
.btn-save {
  background-color: #f0f4f7;
  width: 100%;
  height: 100% !important;
  padding: 0.875rem 0.7rem;
  border: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 1rem;
  span {
    color: var(--text-link);
    font-size: 12px;
    font-weight: 600;
  }
  svg {
    margin-right: 6px;
    width: 18px;
    height: 18px;
    // --stroke-color: #2973e0;
  }
  .material-symbols-outlined {
    font-weight: 500;
    font-size: 1rem;
    margin-right: 8px;
  }
  @media screen and (max-width: 767px) {
    width: 80px;
  }
}
.btn-remove {
  background-color: transparent;
  border: none;
  padding: 0.6rem 0.4rem;
  span {
    color: var(--text-color);
    font-size: 1.2rem;
  }
  svg {
    margin-right: 6px;
    width: 18px;
    height: 18px;
    // --stroke-color: #2973e0;
  }
}
.btn-link {
  background: none;
  border: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 1rem;
  margin-top: 1rem;
  span {
    font-size: 13px;
    font-weight: 600;
    color: var(--text-link);
  }
  .material-symbols-outlined {
    margin-right: 8px;
    font-size: 1rem;
  }
  svg {
    margin-right: 8px;
    width: 1rem;
    height: 1rem;
    --stroke-color: var(--text-link);
  }
}
.btn-actions {
  margin-top: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: end;
  @media screen and (max-width: 600px) {
    justify-content: center !important;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    button {
      margin-bottom: 10px;
    }
  }
  button:nth-child(2),
  button:nth-child(3) {
    margin-left: 8px !important;
  }
}
.btn-stripe {
  background-color: #635bff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 4px;
  padding: 0.6rem 0;
  width: 240px;
  height: 40px;
  span {
    color: var(--color-white);
    font-size: 13px;
    font-weight: 600;
    padding: 10px;
  }
}
.btn-paypal {
  background-color: #ffc43a;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 4px;
  padding: 0.6rem 0px;
  width: 240px;
  height: 40px;
}

.btn-transparent {
  background-color: transparent !important;
  border: 1px solid #eb3b3b;
  span {
    color: #eb3b3b;
  }
}

.ai-btn-link {
  background: none;
  border: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 1rem;
  // min-width: 164px;
  gap: 0.5rem;
  span {
    width: max-content;
    font-size: 13px;
    font-weight: 600;
    color: #004cbb;
  }
  .material-symbols-outlined {
    // margin-right: 8px;
    font-size: 18px;
  }
  svg {
    width: 1.1rem;
    height: 1.1rem;
    // margin-right: 8px;
  }
  svg.disabled {
    --fill-color: #c1c9d5;
  }
}

//admin-theme-buttons
.btn-admin-primary,
.btn-admin-accent,
.btn-admin-cancel,
.btn-admin-accent-active,
.btn-admin-primary-active,
.btn-admin-active {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 13px;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  min-height: 42px;
  position: relative;
  &:active {
    box-shadow: 0px 0px 0px 4px #c9dcf7;
  }
  &:disabled {
    color: #b2cbef;
    border: 1px solid #b2cbef;
    pointer-events: none;
    cursor: no-drop;
    svg {
      --stroke-color: #b2cbef;
    }
  }
  .material-symbols-outlined {
    font-size: 1.2rem;
    margin-right: 6px;
  }
  svg {
    margin-right: 6px;
    width: 18px;
    height: 18px;
  }
}
.btn-admin-active {
  min-height: 48px;
  color: white;
  overflow: hidden;
  position: relative;
  svg {
    --stroke-color: white;
  }
  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    display: none;
  }
  &:hover {
    &::after {
      display: block;
    }
  }
}
.btn-admin-primary {
  border: 1px solid #2973e0;
  background: #fff;
  color: #2973e0;
  padding: 0.75rem 1rem;
  &:hover,
  &:active {
    background-color: #2973e0;
    color: #ffffff;
    svg {
      --stroke-color: #ffffff;
    }
    span {
      color: #ffffff !important;
    }
  }
  svg {
    --stroke-color: #2973e0;
  }
}
.btn-admin-accent {
  border: 1px solid #eb3b3b;
  background: #fff;
  color: #eb3b3b;
  padding: 0.75rem 1rem;
  &:hover,
  &:active {
    background-color: #eb3b3b;
    color: #ffffff;
    svg {
      --stroke-color: #ffffff;
    }
  }
  svg {
    --stroke-color: #eb3b3b;
  }
}
.btn-admin-accent-active,
.btn-admin-primary-active {
  color: #ffffff;
  padding: 0.75rem 1rem;
  outline: none;
  svg {
    --stroke-color: #ffffff;
  }
  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    display: none;
  }
  &:hover {
    &::after {
      display: block;
    }
  }
}
.btn-admin-cancel {
  color: #667182;
  border: 1px solid #667182;
  background: #fff;
  &:hover,
  &:active {
    background-color: #eb3b3b;
    color: #ffffff;
    border: 1px solid #eb3b3b;
    svg {
      --stroke-color: #ffffff !important;
    }
  }
  svg {
    --stroke-color: #667182;
  }
}

.btn-admin-save {
  min-width: 120px;
  padding: 0.75rem;
}
.btn-admin-icon {
  min-width: 142px;
  padding: 0.75rem 0.5rem;
  @media screen and (min-width: 600px) {
    padding: 0.75rem 25px 0.75rem 0.75rem;
  }
}
