.admin-card {
  border: 1px solid var(--border-color-primary);
  border-radius: 6px;
  margin-bottom: 15px;
  box-shadow: var(--box-shadow);
  background-color: var(--color-white) !important;
  ::ng-deep .mat-mdc-form-field {
    // margin-bottom: 1rem;
    .mat-form-field {
      background-color: var(--color-white);
      label {
        margin-top: 6px;
      }
      input {
        background: var(--color-white);
        border-radius: 4px;
        border: 1px solid var(--border-color-primary) !important;
        color: var(--text-color);
        font-size: 14px !important;
        font-weight: 400;
        height: 30px;
        margin-top: 10px;
        &:focus {
          outline: none;
          box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
            rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        }
      }
    }
  }
  // ::ng-deep .mat-mdc-form-field-subscript-wrapper {
  //     display: none !important;
  // }
  .material-symbols-outlined {
    cursor: pointer;
  }
  p {
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    color: var(--text-color);
    margin-bottom: 1rem;
  }
  .margin-right {
    margin-right: 10px !important;
  }
  .mat-mdc-raised-button.mat-primary:not([disabled]).btn-primary {
    padding: 0rem 2.5rem;
    font-weight: 600;
    font-size: 13px;
    height: 42px;
    border-radius: 6px;
  }
}

.admin-card-body {
  border-bottom: 1px solid var(--border-color);
  padding: 1.5rem 3rem;
  .admin-card-title {
    color: var(--text-primary);
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    margin-bottom: 1.2rem;
    margin-top: 0px;
    display: flex;
    align-items: center;
    span {
      padding-right: 8px;
      font-size: 2rem;
    }
  }
  .admin-card-sub-title {
    color: var(--text-primary);
    font-size: 14px;
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 1rem;
  }
  .admin-card-title-des {
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    color: var(--text-color);
  }

  .settings-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.4rem;
  }
}
.admin-card-body.px-0 {
  padding-left: 0;
  padding-right: 0;
  border-bottom: none;
}

@media screen and (max-width: 600px) {
  .admin-card-body {
    padding: 1rem !important;
    .admin-card-title-des,
    .admin-card-title {
      text-align: center;
    }
    .settings-actions,
    .admin-card-title {
      justify-content: center !important;
    }
  }
}

// admin new theme
.admin-screen-container {
  margin-top: -40px;
  .bg-light-blue {
    background-color: #e1f4ff;
  }
  .bg-light-pruple {
    background-color: #ece1ff;
  }
  .bg-light-green {
    background-color: #c2fff4;
  }
  .bg-light-red {
    background-color: #ffeaea;
  }
  .bg-light-orange {
    background-color: #ffeee4;
  }
  .bg-green {
    background-color: rgba(41, 224, 147, 0.1);
  }
  .bg-blue {
    background-color: rgba(41, 115, 224, 0.1);
  }
  .bg-sky-blue {
    background-color: #dcf9ff;
  }
  hr {
    border-top: 1px solid #eff3fa;
    margin: 1.5rem 0;
  }

  .top-header-img {
    .manageImg {
      display: none;
    }
    h6 {
      color: #2d405d;
      font-size: 0.875rem;
      font-weight: 500;
      margin: 0;
      margin-bottom: 10px;
    }
    p {
      font-size: 0.75rem;
      margin-bottom: 0px;
    }
    .icon {
      margin-right: 8px;
      svg {
        width: 1.2rem;
        height: 1.2rem;
      }
    }
    @media screen and (min-width: 767px) {
      position: relative;
      .manageImg {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }

  .card-title {
    font-size: 20px;
    font-weight: 500;
    color: #11294f;
  }
  .card-title-with-icon {
    display: flex;

    svg {
      margin-right: 11px;
    }
  }
  .card-sub-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #11294f;
    margin: 0;
  }
  .card-widget-title {
    color: #2d343f;
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
  }
  .card-title-icon {
    svg {
      width: 1.5rem;
      height: 1.5rem;
      align-items: center;
      --stroke-color: #11294f;
    }
    span {
      margin-left: 10px;
    }
  }
  .card-body {
    box-shadow: 0px 2px 15px rgba(126, 141, 160, 0.15);
    background-color: #ffffff;
    padding: 1.5rem 1.4rem;
    border-radius: 10px;
    margin-bottom: 1rem;
    .card-icon {
      height: 42px;
      width: 42px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 18px 0 0;
      .icon {
        width: 22px;
        height: 22px;
        use {
          width: 100%;
          height: 100%;
        }
      }
    }
    .card-margin {
      margin-top: 1.2rem;
      padding: 0 3.7rem;
    }
    .card-margin-top {
      margin-top: 1.2rem;
    }
    .card-margin-left {
      margin-left: 1.2rem;
    }
    @media screen and (max-width: 767px) {
      padding: 1.5rem 1rem;
      .card-margin {
        padding: 0;
      }
      .card-img {
        margin-bottom: 1rem;
        img {
          width: 100px !important;
          height: 100px !important;
        }
      }
    }
  }
  .btn-actions {
    margin-top: 1.8rem;
  }

    .form-group {
      .form-control {
        border-radius: 10px;
        font-size: 0.875rem;
        color: #2d343f;
        font-weight: 500;
      }
  
      label {
        color: #4b5d7a;
      }
  
      .custom-select {
        -webkit-appearance: none;
        cursor: pointer;
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23333"%3E%3Cpath d="M7 10l5 5 5-5z"/%3E%3C/svg%3E');
        background-repeat: no-repeat;
        background-position: right 10px center;
        background-size: 18px;
      }
  
    }

  .hint {
    font-size: 14px;
    line-height: 1rem;
    font-weight: 400 !important;
    color: #2d343f !important;
  }
  p {
    font-size: 0.875rem;
    line-height: 20px;
    font-weight: 400;
    color: #4b5d7a;
    margin: 0px;
    margin-bottom: 1rem;
  }
  .mb-0 {
    margin-bottom: 0rem !important;
  }
  .card-widget-title {
    color: #2d343f;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }
  .btn-admin-primary {
    min-width: 120px;
  }
  .btn-admin-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 1.4rem;
    // button:nth-child(2) {
    //   margin-left: 1rem;
    // }
    .btn-widget {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }
  @media screen and (max-width: 600px) {
    .widget-sm {
      display: flex;
      flex-direction: column;
      align-items: center;
      button {
        margin-top: 1.2rem;
      }
    }
    .btn-admin-actions {
      display: flex;
      justify-content: center !important;
    }
  }
}

//admin-layout
.admin-layout {
  background-color: #f7fafc;
  .layout-wrapper {
    padding: 1.5rem 0 2.5rem 290px;
    .help-link {
      display: flex;
      justify-content: end;
      margin-bottom: 8px;
      padding-right: 8px;
      .help-icon {
        width: 18px;
        height: 18px;
      }
      a {
        color: var(--text-link);
        font-size: 13px;
        font-weight: 600;
        text-decoration: none;
        display: flex;
        align-items: center;
        span {
          margin-left: 5px;
        }
        .docs-link {
          @media screen and (max-width: 600px) {
            display: none;
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    .layout-wrapper {
      padding: 25px 0 25px 0;
    }
  }
  @media screen and (min-width: 991px) {
    .container {
      max-width: 100% !important;
    }
  }
  @media screen and (min-width: 1400px) {
    .desktop-fixed-width {
      width: 800px;
    }
    .desktop-fixed-lg-width {
      width: 900px;
    }
    .desktop-fixed-xl-width {
      width: 1000px;
    }
  }
}

.open-ai-int-slider {
  mat-slider-visual-thumb {
    .mdc-slider__thumb-knob {
      background-color: #4b5e79;
      height: 30px;
      width: 9px;
      border: none;
      border-radius: 22%;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px rgba(0, 0, 0, 0.12),
        0 1px 5px rgba(0, 0, 0, 0.2);
      top: 20px;
    }

    .mat-focus-indicator {
      background: none;
    }
  }

  .mdc-slider__track {
    position: relative;
  }
  .mdc-slider__track:before {
    content: "";
    width: 100%;
    height: 15px;
    background: repeating-linear-gradient(
      90deg,
      transparent,
      transparent 40px,
      #ccc 40px,
      #ccc 42px
    );
    position: absolute;
    top: -5px;
    left: 0;
    pointer-events: none;
    z-index: -1;
  }
}

.ai-assistant-slider {
  mat-slider-visual-thumb {
    .mdc-slider__thumb-knob {
      border-width: 5px !important;
      border-color: rgba(41, 115, 224, 1) !important;
      padding: 5px !important;
    }
  }
}
